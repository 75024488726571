/* --------------------------------------------------------------( Reset )--- */

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6,
p, blockquote, pre, a, abbr, acronym, address,
big, cite, code, del, dfn, em, img, ins, kbd, q, s,
samp, small, strike, strong, sub, sup, tt, var, b, u, i,
center, dl, dt, dd, ol, ul, li, fieldset, form, label,
legend, table, caption, tbody, tfoot, thead,
tr, th, td, article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  font-family: inherit;
  letter-spacing: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
*:before,
*:after {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  user-select: none;
  margin: 0;
  padding: 0;
}

html {
  background-color: var(--void-bg);
  overflow: hidden;
  min-height: 100vh;
  height: 100vh;
  min-width: 100vw;
  width: 100vw;
  user-select: none;
  cursor: default;
}

body {
  overflow: hidden;
  min-height: 100vh;
  height: 100vh;
  min-width: 100vw;
  width: 100vw;
  user-select: none;
  cursor: default;
}

a,
button {
  cursor: pointer;
}

input,
textarea,
select,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: unset;
  font: inherit;
}

input:focus,
select:focus,
textarea:focus,
button:focus,
[contenteditable='true']:focus,
*:focus {
  outline: none;
}

textarea,
input:not([type='checkbox']),
[contenteditable='true'],
[contenteditable='true'] * {
  user-select: text;
}

a {
  display: block;
  font-style: normal;
}
