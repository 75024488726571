
/* --------------------------------------------------------------( Theme )--- */

body {
  --radius-bs: 0 12px;
  --radius-lg: 12px;
  --radius: 10px;
  --radius-md: 8px;
  --radius-sm: 4px;

  &.mobile-size {
    --hdr-1: calc(23.0px + .4vw);
    --hdr-2: calc(19.0px + .4vw);
    --hdr-3: calc(16.0px + .4vw);
    --hdr-4: calc(12.5px + .4vw);
    --hdr-5: calc(11.0px + .4vw);
    --hdr-6: calc(9.0px + .4vw);
    --txt-5: calc(7.5px + .2vw);
    --txt-4: calc(7.0px + .2vw);
    --txt-3: calc(6.5px + .2vw);
    --txt-2: calc(6.0px + .2vw);
    --txt-1: calc(5.5px + .2vw);
  }
  &.web-size {
    --hdr-1: calc(46px + .4vw);
    --hdr-2: calc(38px + .4vw);
    --hdr-3: calc(32px + .4vw);
    --hdr-4: calc(25px + .4vw);
    --hdr-5: calc(22px + .4vw);
    --hdr-6: calc(18px + .4vw);
    --txt-5: calc(15px + .2vw);
    --txt-4: calc(14px + .2vw);
    --txt-3: calc(13px + .2vw);
    --txt-2: calc(12px + .2vw);
    --txt-1: calc(11px + .2vw);
  }
  
  font-size: var(--txt-3);
  line-height: 1.4em;
  letter-spacing: .06ch;
  input, textarea {
    font-size: var(--txt-3);
    line-height: 1.4em;
    padding: 2px 12px;
  }
  h1 { font-size: var(--hdr-1); line-height: 1.15em; left: -5px; right: -4px; }
  h2 { font-size: var(--hdr-2); line-height: 1.15em; left: -4px; right: -3px; }
  h3 { font-size: var(--hdr-3); line-height: 1.15em; left: -3px; right: -2px; }
  h4 { font-size: var(--hdr-4); line-height: 1.15em; left: -2px; right: -1px; }
  h5 { font-size: var(--hdr-5); line-height: 1.15em; left: -1px; right: -1px; }
  h6 { font-size: var(--hdr-6); line-height: 1.15em; }
  h1, h2, h3 {
    font-weight: 300;
    &.em-1 { font-weight: 400; }
    &.em-2 { font-weight: 500; }
  }
  h4, h5, h6 {
    font-weight: 400;
    &.em-1 { font-weight: 500; }
    &.em-2 { font-weight: 600; }
  }
  .txt-5 { font-size: var(--txt-5); }
  .txt-4 { font-size: var(--txt-4); }
  .txt-3 { font-size: var(--txt-3); }
  .txt-2 { font-size: var(--txt-2); }
  .txt-1 { font-size: var(--txt-1); }
  .txt-5, .txt-4, .txt-3, .txt-2, .txt-1 {
    &.em-1 { font-weight: 500; }
    &.em-2 { font-weight: 600; }
  }
  header, nav, aside, main, footer,
  article, section, div, span,
  label, button, pre, p, blockquote {
    font-size: var(--txt-3);
    &.em-1 { font-weight: 500; }
    &.em-2 { font-weight: 600; }
  }


  &.light-theme {
    --cursor-dot: rgba(100, 100, 100, 0.8);
    --cursor-ring: rgba(209, 57, 46, 0.8);
    --cursor-link: rgba(255, 255, 255, 0.9);
    --txt-shadow: 0 0 1px rgba(0, 0, 0, .5);
    --txt-shadow-maybe: 0 0 1px rgba(0, 0, 0, .35);
    --void: #ffffff;
    --loud: #d1392e;
    --link: #d1392e;
  
    --void-bgt-rgb: 255, 255, 255;
    --void-bgt: #ffffff;
    --void-ttl: #666666;
    --void-txt: #555555;
  
    --base-bgt-rgb: 233, 233, 233;
    --base-inv-rgb: 42, 42, 42;
    --base-bgt: #e9e9e9;
    --base-ttl: #666666;
    --base-txt: #555555;
  
    --util-bgt: #aaaaaa;
    --util-ttl: #eeeeee;
    --util-txt: #ffffff;
  
    --face-bgt: #999999;
    --face-ttl: #eeeeee;
    --face-txt: #ffffff;
  
    --loud-bgt: #d1392e;
    --loud-ttl: #eeeeee;
    --loud-txt: #ffffff;
  }
  &.dark-theme {
    --cursor-dot: rgba(200, 200, 200, 0.8);
    --cursor-ring: rgba(209, 88, 46, 0.8);
    --cursor-link: rgba(255, 255, 255, 0.8);
    --txt-shadow: 0 0 1px rgba(0, 0, 0, .45);
    --txt-shadow-maybe: 0 0 1px rgba(0, 0, 0, .25);
    --void: #181818;
    --loud: #f27750;
    --link: #f27044;
  
    --void-bgt-rgb: 32, 32, 32;
    --void-bgt: #202020;
    --void-ttl: #dddddd;
    --void-txt: #cccccc;
    
    --base-bgt-rgb: 42, 42, 42;
    --base-inv-rgb: 233, 233, 233;
    --base-bgt: #2a2a2a;
    --base-ttl: #dddddd;
    --base-txt: #cccccc;
  
    --util-bgt: #393939;
    --util-ttl: #eeeeee;
    --util-txt: #dddddd;
  
    --face-bgt: #505050;
    --face-ttl: #ffffff;
    --face-txt: #eeeeee;
  
    --loud-bgt: #f26639;
    --loud-ttl: #eeeeee;
    --loud-txt: #ffffff;
  }

  background-color: var(--void-bgt);
  color: var(--void-txt);
  h1, h2, h3, h4, h5, h6 {
    color: var(--base-txt);
    &.primary {
      color: var(--loud);
      padding: 0 24px;
    }
    > span.highlight {
      color: var(--link);
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
    }
  }
  a.link:not(.nav) {
    color: var(--link);
  }
  input, textarea {
    background-color: var(--void-bgt);
    color: var(--void-txt);
    border-color: var(--void-txt);
    border-radius: 0 var(--radius);
    line-height: 24px;
  }
  label.item {
    display: flex;
    width: 100%;
    height: fit-content;
    border-radius: 0 0 0 14px;
    line-height: 1.6em;
    overflow: hidden;
    span {
      background-color: var(--face-bgt);
      color: var(--face-txt);
      padding: 2px 22px 2px 14px;
      font-weight: 500;
      margin-right: -12px;
      z-index: 0;
    }
    input {
      background-color: var(--void-bgt);
      border: 1px solid var(--face-bgt);
      color: var(--void-txt);
      text-shadow: none;
      z-index: 1;
      &+span {
        padding: 2px 22px 2px 20px;
        margin-left: -6px;
      }
    }
    &.small {
      margin-top: 4px;
      font-variant: all-small-caps;
      span {
        padding: 2px 20px 2px 10px;
        font-size: 15px;
        line-height: 16px;
        white-space: nowrap;
      }
      input {
        font-size: 15px;
        line-height: 21px;
        &+span {
          padding: 2px 20px 2px 14px;
          margin-left: -6px;
        }
      }
    }
  }

  .face > section,
  .loud > section,
  .void {
    background-color: var(--void-bgt);
    color: var(--void-txt);
    h1, h2, h3, h4, h5, h6 {
      color: var(--base-bgt);
    }
    a.link:not(.nav) {
      color: var(--link);
    }
    input, textarea {
      background-color: var(--void-bgt);
      color: var(--void-txt);
      border-color: var(--face-bgt);
    }
  }
  header, footer,
  aside > div,
  main  > div:not(.viewport),
  //main  > div.viewport > div,
  .base {
    background-color: var(--base-bgt);
    color: var(--base-txt);
    h1, h2, h3, h4, h5, h6 {
      color: var(--base-ttl);
    }
  }
  section {
    border-radius: var(--radius-bs);
  }
  .util {
    background-color: var(--util-bgt);
    color: var(--util-txt);
    font-weight: 500;
    text-shadow: var(--txt-shadow);
    h1, h2, h3, h4, h5, h6 {
      color: var(--util-ttl);
    }
  }
  .face {
    background-color: var(--face-bgt);
    color: var(--face-txt);
    font-weight: 500;
    text-shadow: var(--txt-shadow);
    h1, h2, h3, h4, h5, h6 {
      color: var(--face-ttl);
    }
  }
  .loud {
    background-color: var(--loud-bgt);
    color: var(--loud-txt);
    font-weight: 500;
    text-shadow: var(--txt-shadow);
    h1, h2, h3, h4, h5, h6 {
      color: var(--loud-ttl);
    }
    a.link:not(.nav) {
      color: var(--void);
    }
    button, a.button {
      background-color: var(--util-bgt);
      color: var(--util-txt);
    }
  }
  button, a.button {
    background-color: var(--face-bgt);
    color: var(--face-txt);
    border-radius: var(--radius-md);
    padding: 2px 22px;
    cursor: pointer;
  }
  button {
    line-height: 24px;
  }
}

/* --------------------------------------------------------------( Paths )--- */

$raleway-font-path: './font/Raleway' !default;
$montserrat-font-path: './font/Montserrat' !default;
$material-icons-path: './icon/Material' !default;

/* --------------------------------------------------------------( Fonts )--- */

$heading-font-stack: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif !default;
$body-font-stack: -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif !default;

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Montserrat Light'), local('Montserrat-Light'),
    url('#{$montserrat-font-path}/Montserrat-Light-2.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('#{$montserrat-font-path}/Montserrat-Regular-2.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url('#{$montserrat-font-path}/Montserrat-Medium-2.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Raleway ExtraLight'), local('Raleway-ExtraLight'),
    url('#{$raleway-font-path}/Raleway-ExtraLight-2.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Raleway Light'), local('Raleway-Light'),
    url('#{$raleway-font-path}/Raleway-Light-2.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Raleway'), local('Raleway-Regular'),
    url('#{$raleway-font-path}/Raleway-Regular-2.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

body {
  font-family: $body-font-stack;
  font-weight: 400;

  p {
    margin-top: 0;
    margin-bottom: 0;
    + p { margin-top: 12px; }
  }

  span {
    &:not(.inline) {
      display: inline-block;
    }
    &.tag {
      display: inline-block;
      margin: 2px;
      padding: 0 1.5ch;
      line-height: 22px;
      border-radius: 11px;
      background-color: var(--face-bgt);
      color: var(--face-txt);
      font-size: var(--txt-2);
      font-weight: 400;
      text-shadow: var(--txt-shadow);
      &.ctr {
        cursor: not-allowed;
      }
    }
  }
  div.drop-list {
    position: absolute;
    background-color: var(--util-bgt);
    color: var(--util-txt);
    font-weight: 500;
    display: flex;
    flex-direction: column;
    height: fit-content;
    max-height: 280px;
    width: 100%;
    max-width: 460px;
    gap: 1px;
    overflow-y: auto;
    border-radius: 10px;
    border: 1px solid var(--util-bgt);
    cursor: pointer;
    z-index: 10;
    .drop-item {
      background-color: var(--void-bgt);
      color: var(--void-txt);
      font-size: var(--txt-2);
      text-shadow: none;
      padding: 2px 12px;
      &.active {
        background-color: var(--face-bgt);
        color: var(--face-txt);
        text-shadow: var(--txt-shadow);
      }
    }
  }

  h1, h2, h3, h4, h5, h6 {
    position: relative;
    font-family: $heading-font-stack;
  }
}

