
@import "reset";
@import "theme";

/* --------------------------------------------------------------( Media )--- */

// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$xlarge-screen-up: 1601px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$medium-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$xlarge-and-up: "only screen and (min-width : #{$xlarge-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;



/* ------------------------------------------------------------( Sanity )--- */

a:link,
a:visited,
a:hover,
a:active  {
  /* Applies to activated links */
  text-decoration:  none;
  font-weight:      inherit;
  color:            inherit;
}
section, div, span {
  position: relative;
}
span {
  display: inline-block;
}



/* -------------------------------------------------------------( Utils )--- */

/* text-align */
.left     { text-align: left;   }
.center   { text-align: center; }
.right    { text-align: right;  }
.etc      {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.italic   { font-style: italic; }

/* position */
.fixed    { position: fixed    }
.absolute { position: absolute }
.relative { position: relative }

/* display */
.block  { display: block;  }
.inline { display: inline; }
.inline-block,
.iblock { display: inline-block; }


[cover] {
  min-width: 100%;
  min-height: 100%;
  height: 100%;
}
[fit] {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
}
[contain] {
  overflow: hidden;
}
[layer] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
[depth-0] { z-index: 0; }
[depth-1] { z-index: 1; }
[depth-2] { z-index: 2; }
[depth-3] { z-index: 3; }
[depth-10000] { z-index: 100000; }
[flex], [horizontal], [vertical] {
  display: flex;
  flex-basis: auto;
  &[base-auto] { flex-basis: auto; }
  &[base-0] { flex-basis: 0; }
  &[base-1] { flex-basis: 1; }
  &[base-2] { flex-basis: 2; }
  &[base-3] { flex-basis: 3; }
  &[base-4] { flex-basis: 4; }
  flex-grow: 0;
  &[grow-0] { flex-grow: 0; }
  &[grow-1] { flex-grow: 1; }
  &[grow-2] { flex-grow: 2; }
  &[grow-3] { flex-grow: 3; }
  &[grow-4] { flex-grow: 4; }
  flex-wrap: nowrap;
  &[wrap] { flex-wrap: wrap; }
  &[no-wrap] { flex-wrap: nowrap; }
  justify-content: space-evenly;
  &[collate-left] { justify-content: flex-start; }
  &[collate-center] { justify-content: center; }
  &[collate-right] { justify-content: flex-end; }
  &[collate-between] { justify-content: space-between; }
  &[collate-around] { justify-content: space-around; }
  &[collate-even] { justify-content: space-evenly; }
}
[horizontal] {
  flex-direction: row;
  align-items: center;
  &[align-top] { align-items: flex-start; }
  &[align-center] { align-items: center; }
  &[align-bottom] { align-items: flex-end; }
}
[vertical] {
  flex-direction: column;
  align-items: center;
  &[align-left] { align-items: flex-start; }
  &[align-center] { align-items: center; }
  &[align-right] { align-items: flex-end; }
}


/* -------------------------------------------------------------( Cursor )--- */
[curses] {
  &, a {
    cursor: none;
    a.link, a.button {
      cursor: pointer;
    }
  }
}
[cursing] {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  border-radius: 50%;
  &[hollow] {
    width: 30px;
    height: 30px;
    border: 2px solid var(--cursor-ring);
    z-index: 12000;
  }
  &[point] {
    width: 8px;
    height: 8px;
    left: -4px;
    top: -4px;
    z-index: 11000;
    background: var(--cursor-dot);
  }
}
[pointless] {
  pointer-events: none;
}



/* ---------------------------------------------------------------( Form )--- */


.led {
  position: absolute;
  top: 6px;
  bottom: 6px;
  left: 12px;
  width: 3px;
  transition: background-color 200ms linear;
}
label .led {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1ch;
  width: .8ch;
  height: .8ch;
  margin: auto 0;
  border-radius: 50%;
}
button .led {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
  width: 4px;
  height: 1.4ch;
  margin: auto 0;
  border-radius: 2px;
}
@keyframes pulse {
  0% { transform: scale(1); opacity: 1; }
  10% { transform: scale(2.5); opacity: 0; }
  30% { transform: scale(2.5); opacity: 0; }
  92% { transform: scale(1); opacity: 1; }
}
@keyframes signal {
  0% {  opacity: 0; }
  49% { opacity: 0; }
  50% { opacity: 1; }
}
.led-glow .led.glow {
  animation: pulse 2s ease-out infinite 150ms;
}
.led.signal {
  animation: signal 1s linear infinite 220ms;
}
.led-glow {
  .led.no-signal {
    animation: signal 1s linear infinite 220ms;
  }
  .led.signal {
    animation: unset;
  }
}
.on > .led,
.led.on {
  background-color: var(--void-txt);
  transition: background-color 200ms linear;
}
.led.blue {
  background-color: #4aa1ff;
  transition: background-color 200ms linear;
}
.led.green {
  background-color: #3cca27;
  transition: background-color 200ms linear;
}
.led.orange {
  background-color: #fda040;
  transition: background-color 200ms linear;
}
.led.red {
  background-color: #e35843;
  transition: background-color 200ms linear;
}
.off > .led,
.led.off {
  background-color: var(--void-bgt);
  transition: background-color 200ms linear;
}

form {
  .field {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 1;
    width: 100%;
    padding: 0;
    
    label {
      position: relative;
      padding: 0 4px 0 6px;
      width: 100%;
    }
    input {
      position: relative;
      margin: 2px 0;
      line-height: 1.8em;
      width: 100%;
    }
    .drift {
      position: relative;
      padding: 0 4px 0 6px;
      margin: -2px 0 -22px;
      width: 100%;
      color: #fda040;
      font-size: var(--txt-2);
      white-space: pre-wrap;
      text-shadow: var(--txt-shadow-maybe);
    }
  }
  .choice {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-basis: 1;
    gap: 8px;
    width: 100%;
    padding: 0px;

    //background-color: var(--void-bgt);
    color: var(--void-txt);
    margin: 2px 0;
    line-height: 1.8em;
    overflow: hidden;
    
    > span {
      background-color: rgba(var(--void-bgt-rgb), .4);
      border: 1px solid rgba(var(--base-inv-rgb), .15);
      border-radius: 0 var(--radius);
      transition: background-color 200ms linear;
      &:hover:not(.chosen) {
        background-color: var(--void-bgt);
        transition: background-color 350ms linear;
      }
      &.chosen {
        background-color: var(--loud-bgt);
        color: var(--loud-txt);
        transition: background-color 350ms linear;
      }
      line-height: 1.8em;
      text-align: center;
      padding: 0 4px 0 6px;
      width: 100%;
    }
  }
  > .input {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 24px;
    padding: 24px 0;
    width: 100%;
  }
  > .control {
    margin-top: 12px;
    min-height: 36px;
    width: 100%;
    text-align: center;
    padding: 4px 24px;
  }
  button, .control button {
    position: relative;
    padding: 2px 22px 2px 28px;
  }
}


/* -------------------------------------------------------------( Layout )--- */

.main-layout {
  display: flex;
  flex-direction: column;
  grid-column-gap: 0;
  grid-row-gap: 0;
  min-height: 100vh;
}
@media #{$medium-and-up} {
  .main-layout {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: minmax(240px, auto) minmax(auto, 1000px) minmax(240px, auto);
    &.env-test { grid-row-gap: 1px; grid-column-gap: 1px; }
    &.env-live { grid-row-gap: 0; grid-column-gap: 0; }
  }
}
body {
  min-height: 100vh;
  &.env-test .main-layout {
    header a.link {
      border-top: 1px solid var(--base-bgt);
      transition: border-top .3s ease;
      &:not(.access):hover {
        border-top: 1px solid var(--loud);
        transition: border-top .3s ease;
      }
      &.access:hover {
        border-top: 1px solid var(--void-txt);
        transition: border-top .3s ease;
      }
    }
    main a.link, footer a.link {
      border-bottom: 1px solid var(--base-bgt);
        transition: border-bottom .3s ease;
        &:hover {
          border-bottom: 1px solid var(--loud);
          transition: border-bottom .3s ease;
        }
        &.access:hover {
          border-bottom: 1px solid var(--void-txt);
          transition: border-bottom .3s ease;
        }
    }
  }
  .main-layout {
    header {
      position: relative;
      text-align: right;

      &.logo {
        padding: 5px 0 5px 30px;
        height: 94px;
        img {
          position: absolute;
          right: -40px;
          height: 80px;
          z-index: 10;
        }
      }
    }

    main {
      position: relative;
      flex: 1;
      padding: 20px;
    }

    aside.present {
      position: relative;
      padding: 20px;
    }
    
    aside.context {
      position: relative;
      padding: 20px;
    }

    footer {
      position: relative;
      padding: 30px;
      height: 80px;
      text-align: center;
    }
  }

  .viewport {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 24px;
    padding: 4px;
    height: 100%;
    width: 100%;
    max-width: 960px;
    margin: auto;
    overflow-y: auto;
  }
  .surface {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0;
    padding: 0;
    width: 100%;
    border-radius: 0 var(--radius);
  }
  .section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 24px;
    padding: 24px 0;
    width: 100%;
  }
  .line {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    gap: 24px;
    padding: 0 24px;
    width: 100%;
    height: fit-content;
  }
  .item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 1;
    width: 100%;
    padding: 0;
  }
}